import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container id="section-services">
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Services row wrap stretch>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 4.5625rem 14.236vw 6.6875rem;
  position: relative;
  text-align: center;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 2;
  }
  &:before {
    width: 2.875rem;
    height: 2.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    top: -1.4375rem;
  }
  &:after {
    width: 1.125rem;
    height: 1.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    top: -0.5625rem;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.9375rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 1.75rem;
  margin-bottom: 1.1875rem;
`

const Services = styled(FlexBox)``
