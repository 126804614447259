import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <SVG src={icon} width="40" height="30" alt={label} /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 20%;
  margin-top: 3.375rem;

  @media (max-width: 1023px) {
    width: 50%;
    margin-top: 3rem;
  }
`

const SVG = styled.img`
  width: auto;
  height: 1.875rem;
`

const Label = styled.span`
  display: block;
  max-width: 140px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin: 0.6875rem auto 0;
  text-transform: uppercase;
`
