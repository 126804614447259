import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
  title?: string
}

export const RoomsSlider = memo(function RoomsSlider({ rooms, title }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    autoAdjustSlidesPerView: false,
    loop: rooms.length > 2 ? true : false,
    slidesPerView: 3,
    spacing: 4,
    breakpoints: {
      '(max-width: 1023px)': {
        centered: true,
        loop: rooms.length > 1 ? true : false,
        slidesPerView: 1.15,
        spacing: 3,
      },
    },
  })

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Wrapper>
        <FadeInUp>
          <Slider
            className="keen-slider"
            data-keen-slider-pe="true"
            ref={sliderRef}
          >
            {rooms
              .sort(
                (a, b) =>
                  new Date(a.dateCreated).getTime() -
                  new Date(b.dateCreated).getTime(),
              )
              .map((item, index) => (
                <Room className="keen-slider__slide" key={index} {...item} />
              ))}
          </Slider>
          <Arrows row space="between">
            <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || slider.next()}
            />
          </Arrows>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.5rem auto 5.875rem;
  padding: 2.75rem 6.458vw 5.375rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: calc(100% - 45.417vw);
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 22.708vw;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto 3.75rem;
    padding: 2.25rem 0 3.75rem;
    &:before {
      width: 100%;
      left: 0;
    }
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 1.75rem;
  position: relative;
  z-index: 3;
`

const Wrapper = styled.div`
  margin-top: 2.125rem;
  position: relative;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const Slider = styled.div`
  position: relative;
  z-index: 2;
`

const Arrows = styled(FlexBox)`
  width: calc(100% + 9.875rem);
  position: absolute;
  top: 50%;
  left: -4.9375rem;
  transform: translateY(-50%);

  > div {
    background: none;
    &:hover {
      background: none;
      svg {
        stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
      }
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight1};
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
