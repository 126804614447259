import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { Move, User } from 'react-feather'
import { Link } from 'react-scroll'

export interface Props {
  availabilityURL?: string
  description?: string
  languageCode: string
  languagePrefix?: string
  pax?: string
  size?: string
  title?: string
}

export const RoomIntro = memo(function RoomIntro({
  availabilityURL,
  description,
  languageCode,
  pax,
  size,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {pax || size ? (
          <Info dial={5} row wrap>
            {pax ? (
              <FadeInUp>
                <Pax dial={5} row wrap>
                  <User />
                  {pax}
                </Pax>
              </FadeInUp>
            ) : null}
            {size ? (
              <FadeInUp>
                <Size dial={5} row wrap>
                  <Move />
                  {size}
                </Size>
              </FadeInUp>
            ) : null}
          </Info>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {availabilityURL ? (
          <FadeInUp>
            <AvailabilityButton
              label={useVocabularyData('check-availability', languageCode)}
              rel="noopener"
              target="_blank"
              URL={availabilityURL}
            />
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <ServicesButton offset={-159} to="section-services" smooth>
            {useVocabularyData('discover-services', languageCode)}
          </ServicesButton>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.0625rem auto 0;
  padding: 0 17.569vw;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: calc(100% - 35.139vw);
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-bottom: 0;
    position: absolute;
    top: 0.75rem;
    bottom: 0;
    left: 17.569vw;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
    padding: 0 1.9375rem;
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  max-width: 45.139vw;
  margin: auto;
  padding-bottom: 3.125rem;

  @media (max-width: 1023px) {
    max-width: none;
    padding-bottom: 0;
  }
`

const Title = styled.h2`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.75rem;
  padding: 0 5.694vw;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-top: 2.75rem;
  text-transform: uppercase;

  svg {
    width: auto;
    height: 1.5rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 0.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 1.5rem;
  }
`

const Pax = styled(FlexBox)`
  margin: 0 1.4375rem;
`

const Size = styled(FlexBox)`
  margin: 0 1.4375rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const AvailabilityButton = styled(Button)`
  margin-top: 2.75rem;
`

const ServicesButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.0375rem;
  line-height: 0.9375rem;
  margin-top: 1.875rem;
  padding-bottom: 0.25rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    &:after {
      animation: lineAnimation 1s forwards;
    }
  }
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    bottom: -0.25rem;
    right: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @keyframes lineAnimation {
    49% {
      right: 0;
      left: 100%;
    }
    50% {
      left: 0;
      right: 100%;
    }
    100% {
      right: 0;
      left: 0;
    }
  }
`
